<template>
  <div class="main" data-title="公告列表列表" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">公告列表</h2>
      </div>
    </div>
    <el-card class="main-content register-box" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">详细内容</div>
        <div class="jue-card-btns">
          <el-button
            @click="$router.push({name: 'news-list'})"
            class="btn"
            icon="el-icon-arrow-left"
          >返回列表</el-button>
          <el-button
            @click="$router.push({name: 'news-edit', query:{
              news_id: $route.query.news_id
            }})"
            class="btn"
            icon="el-icon-edit"
            type="primary"
            v-if="getAuth('/news/list','edit')"
          >编辑</el-button>
        </div>
      </div>

      <el-row :gutter="20">
        <el-col :span="24">
          <h3 class="news-title">{{form.title}}</h3>
        </el-col>
        <el-col :span="24">
          <div class="news-content" v-html="form.content"></div>
        </el-col>
        <el-col :span="24">
          <div class="news-info">
            <span>{{form.created_at}}</span>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.getEditData()
  },
  methods: {
    getAuth(path, str) {
      let pa = path.split('/')
      if (this.$store.state.auth[pa[1]][pa[2]][str] == undefined) {
        return false
      } else {
        return true
      }
    },
    getEditData() {
      let news_id = this.$route.query.news_id
      this.$axios
        .post('/news/detail', {
          news_id: this.$route.query.news_id
        })
        .then(res => {
          this.form = res.data.data
        })
    }
  }
}
</script>
<style lang="less" scoped>
.news-title {
  font-size: 24px;
}
.news-content {
  padding: 40px;
  text-indent: 24px;
  line-height: 1.6;
  p {
    padding: 5px 0;
  }
}
.news-info {
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1.6;
}
</style>